import { customFetch } from './UserAPI';

const login = async ({ email, password, recaptchaToken }) => customFetch('POST', '/login', { email, password, recaptchaToken });
const loginWithGoogle = async ({ token }) => customFetch('POST', '/oauth/google/login', { token });
const signupWithGoogle = async ({
  token, orgID, orgToken, referralCode,
}) => customFetch('POST', '/oauth/google/signup', {
  token, orgID, orgToken, referralCode,
});
const signup = async (formFields) => customFetch('POST', '/signup', formFields);

export {
  login,
  loginWithGoogle,
  signupWithGoogle,
  signup,
};
